import React from "react";
import styled from "@emotion/styled";

import mediaqueries from "@styles/media";

import { Icon } from '@types';

const Logo: Icon = ({ fill = "white" }) => {
  return (
    <LogoContainer>
      <svg fill={fill} height="30px" version="1.1" viewBox="0 0 157.47 24.054" xmlns="http://www.w3.org/2000/svg">
        <g transform="translate(-57.995 -182.1)">
          <g strokeWidth=".26458" aria-label="Morgan Ridel">
          <path d="m61.805 186.19 3.3274 14.427h3.81l3.2766-14.427v14.427h3.81v-18.517h-5.7404l-3.2512 14.732-3.3528-14.732h-5.6896v18.517h3.81z"/>
          <path d="m85.147 186.67c-4.191 0-6.7564 2.7686-6.7564 7.2644 0 4.5212 2.5654 7.2644 6.7818 7.2644 4.1656 0 6.7818-2.7686 6.7818-7.1628 0-4.6482-2.5146-7.366-6.8072-7.366zm0.0254 2.8702c1.9558 0 3.2258 1.7526 3.2258 4.445 0 2.5654-1.3208 4.3434-3.2258 4.3434-1.9304 0-3.2258-1.778-3.2258-4.3942s1.2954-4.3942 3.2258-4.3942z"/>
          <path d="m94.63 186.9v13.716h3.556v-7.2898c0-2.0828 1.0414-3.1242 3.1242-3.1242 0.381 0 0.635 0.0254 1.1176 0.1016v-3.6068c-0.2032-0.0254-0.3302-0.0254-0.4318-0.0254-1.6256 0-3.048 1.0668-3.81 2.921v-2.6924z"/>
          <path d="m113.26 186.9v2.1082c-1.0668-1.6256-2.2352-2.3368-3.81-2.3368-3.2512 0-5.6896 3.175-5.6896 7.4168 0 4.2672 2.2352 7.112 5.6134 7.112 1.6002 0 2.5654-0.4826 3.8862-1.9812v1.8542c0 1.651-1.2446 2.794-3.0226 2.794-1.3462 0-2.2352-0.5588-2.5146-1.6002h-3.683c0.0254 1.0668 0.4318 1.8034 1.3462 2.5654 1.0922 0.889 2.6162 1.3208 4.7244 1.3208 4.1402 0 6.5278-1.8542 6.5278-5.08v-14.173zm-3.048 2.7432c1.778 0 3.0988 1.8542 3.0988 4.3942 0 2.4892-1.2954 4.191-3.1496 4.191-1.6764 0-2.8448-1.7018-2.8448-4.191 0-2.5908 1.1684-4.3942 2.8956-4.3942z"/>
          <path d="m131.74 200.18c-0.635-0.5842-0.8128-0.9652-0.8128-1.6764v-7.62c0-2.794-1.905-4.2164-5.6134-4.2164s-5.6388 1.5748-5.8674 4.7498h3.429c0.1778-1.4224 0.762-1.8796 2.5146-1.8796 1.3716 0 2.0574 0.4572 2.0574 1.3716 0 0.4572-0.2286 0.8636-0.6096 1.0668-0.4826 0.254-0.4826 0.254-2.2352 0.5334l-1.4224 0.254c-2.7178 0.4572-4.0386 1.8542-4.0386 4.318 0 1.1684 0.3302 2.1336 0.9652 2.8194 0.7874 0.8128 1.9812 1.2954 3.2004 1.2954 1.524 0 2.921-0.6604 4.1656-1.9558 0 0.7112 0.0762 0.9652 0.4064 1.3716h3.8608zm-4.2926-5.08c0 2.0574-1.016 3.2258-2.8194 3.2258-1.1938 0-1.9304-0.635-1.9304-1.651 0-1.0668 0.5588-1.5748 2.032-1.8796l1.2192-0.2286c0.9398-0.1778 1.0922-0.2286 1.4986-0.4318z"/>
          <path d="m134.17 186.9v13.716h3.556v-8.2296c0-1.6256 1.143-2.6924 2.8702-2.6924 1.524 0 2.286 0.8382 2.286 2.4638v8.4582h3.556v-9.1948c0-3.0226-1.6764-4.7498-4.5974-4.7498-1.8542 0-3.0988 0.6858-4.1148 2.2098v-1.9812z"/>
          <path d="m160.98 193.28h4.3688c1.651 0 2.3622 0.6604 2.3622 2.2098 0 0.1524 0 0.4064-0.0254 0.7366-0.0254 0.4826-0.0254 0.9398-0.0254 1.2192 0 1.7272 0.1016 2.286 0.5842 3.175h4.0894v-0.6858c-0.5842-0.3302-0.8128-0.7112-0.8128-1.524-0.1016-5.461-0.2032-5.715-2.5654-6.731 2.0828-0.8128 3.1242-2.3114 3.1242-4.572 0-1.4732-0.508-2.8194-1.397-3.7338-0.8382-0.8636-2.0066-1.27-3.5814-1.27h-9.9314v18.517h3.81zm0-3.175v-4.826h4.5974c1.0922 0 1.5494 0.1016 2.0066 0.4826s0.6858 1.016 0.6858 1.8796c0 0.889-0.254 1.6002-0.6858 1.9812-0.4064 0.3556-0.9144 0.4826-2.0066 0.4826z"/>
          <path d="m178.75 186.9h-3.556v13.716h3.556zm0-4.8006h-3.556v3.175h3.556z"/>
          <path d="m190.83 200.62h3.556v-18.517h-3.556v6.5786c-0.889-1.3716-2.0828-2.0066-3.7846-2.0066-3.2766 0-5.7658 3.1496-5.7658 7.2898 0 1.8542 0.5588 3.7338 1.4732 5.0546 0.9398 1.3208 2.6162 2.1844 4.2926 2.1844 1.7018 0 2.8956-0.635 3.7846-1.9812zm-2.9972-10.973c1.8034 0 2.9972 1.7526 2.9972 4.3688 0 2.4892-1.2192 4.2164-2.9972 4.2164s-2.9972-1.7526-2.9972-4.2672c0-2.54 1.2192-4.318 2.9972-4.318z"/>
          <path d="m209.38 194.88c0.0254-0.3048 0.0254-0.4318 0.0254-0.6096 0-1.3716-0.2032-2.6416-0.5334-3.6068-0.9144-2.4892-3.1242-3.9878-5.8928-3.9878-3.937 0-6.35 2.8448-6.35 7.4422 0 4.3942 2.3876 7.0866 6.2738 7.0866 3.0734 0 5.5626-1.7526 6.35-4.445h-3.5052c-0.4318 1.0922-1.4224 1.7272-2.7178 1.7272-1.016 0-1.8288-0.4318-2.3368-1.1938-0.3302-0.508-0.4572-1.1176-0.508-2.413zm-9.144-2.3622c0.2286-2.0828 1.1176-3.1242 2.6924-3.1242 0.889 0 1.7018 0.4318 2.2098 1.143 0.3302 0.508 0.4826 1.0414 0.5588 1.9812z"/>
          <path d="m215.46 182.1h-3.556v18.517h3.556z"/>
          </g>
        </g>
      </svg>
    </LogoContainer>
  );
};

export default Logo;

const LogoContainer = styled.div`
  .Logo__Mobile {
    display: none;
  }

  ${mediaqueries.tablet`
    .Logo__Desktop {
      display: none;
    }

    .Logo__Mobile{
      display: block;
    }
  `}
`;
